import React, { useState, useEffect } from "react"

export const myContext = React.createContext()

const Provider = props => {
	const [loading, setLoading] = useState(true)
	useEffect(() => {
		setTimeout(() => {
			setLoading(false)
		}, 1000)
	}, [])

	return (
		<myContext.Provider
			value={{
				loading,
			}}
		>
			{props.children}
		</myContext.Provider>
	)
}

export default ({ element }) => <Provider>{element}</Provider>
