// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-android-js": () => import("./../src/pages/android.js" /* webpackChunkName: "component---src-pages-android-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-helpful-tools-js": () => import("./../src/pages/helpful-tools.js" /* webpackChunkName: "component---src-pages-helpful-tools-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-about-lupus-js": () => import("./../src/pages/information-about-lupus.js" /* webpackChunkName: "component---src-pages-information-about-lupus-js" */),
  "component---src-pages-ios-js": () => import("./../src/pages/ios.js" /* webpackChunkName: "component---src-pages-ios-js" */),
  "component---src-pages-latest-lupus-research-js": () => import("./../src/pages/latest-lupus-research.js" /* webpackChunkName: "component---src-pages-latest-lupus-research-js" */),
  "component---src-pages-lupus-diagnostic-guidelines-index-js": () => import("./../src/pages/lupus-diagnostic-guidelines/index.js" /* webpackChunkName: "component---src-pages-lupus-diagnostic-guidelines-index-js" */),
  "component---src-pages-lupus-diagnostic-guidelines-newest-criteria-for-lupus-diagnosis-js": () => import("./../src/pages/lupus-diagnostic-guidelines/newest-criteria-for-lupus-diagnosis.js" /* webpackChunkName: "component---src-pages-lupus-diagnostic-guidelines-newest-criteria-for-lupus-diagnosis-js" */),
  "component---src-pages-lupus-diagnostic-guidelines-quiz-do-i-have-lupus-js": () => import("./../src/pages/lupus-diagnostic-guidelines/quiz-do-i-have-lupus.js" /* webpackChunkName: "component---src-pages-lupus-diagnostic-guidelines-quiz-do-i-have-lupus-js" */),
  "component---src-pages-lupus-diagnostic-guidelines-signs-and-symptoms-js": () => import("./../src/pages/lupus-diagnostic-guidelines/signs-and-symptoms.js" /* webpackChunkName: "component---src-pages-lupus-diagnostic-guidelines-signs-and-symptoms-js" */),
  "component---src-pages-lupus-lab-tests-diagnosing-and-monitoring-js": () => import("./../src/pages/lupus-lab-tests-diagnosing-and-monitoring.js" /* webpackChunkName: "component---src-pages-lupus-lab-tests-diagnosing-and-monitoring-js" */),
  "component---src-pages-lupus-resources-js": () => import("./../src/pages/lupus-resources.js" /* webpackChunkName: "component---src-pages-lupus-resources-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-the-referral-system-js": () => import("./../src/pages/the-referral-system.js" /* webpackChunkName: "component---src-pages-the-referral-system-js" */),
  "component---src-pages-treatment-options-index-js": () => import("./../src/pages/treatment-options/index.js" /* webpackChunkName: "component---src-pages-treatment-options-index-js" */),
  "component---src-pages-treatment-options-medications-azathioprine-js": () => import("./../src/pages/treatment-options/medications/azathioprine.js" /* webpackChunkName: "component---src-pages-treatment-options-medications-azathioprine-js" */),
  "component---src-pages-treatment-options-medications-belimumab-js": () => import("./../src/pages/treatment-options/medications/belimumab.js" /* webpackChunkName: "component---src-pages-treatment-options-medications-belimumab-js" */),
  "component---src-pages-treatment-options-medications-corticosteroids-js": () => import("./../src/pages/treatment-options/medications/corticosteroids.js" /* webpackChunkName: "component---src-pages-treatment-options-medications-corticosteroids-js" */),
  "component---src-pages-treatment-options-medications-cyclophosphamide-js": () => import("./../src/pages/treatment-options/medications/cyclophosphamide.js" /* webpackChunkName: "component---src-pages-treatment-options-medications-cyclophosphamide-js" */),
  "component---src-pages-treatment-options-medications-hydroxychloroquine-js": () => import("./../src/pages/treatment-options/medications/hydroxychloroquine.js" /* webpackChunkName: "component---src-pages-treatment-options-medications-hydroxychloroquine-js" */),
  "component---src-pages-treatment-options-medications-index-js": () => import("./../src/pages/treatment-options/medications/index.js" /* webpackChunkName: "component---src-pages-treatment-options-medications-index-js" */),
  "component---src-pages-treatment-options-medications-leflunomide-js": () => import("./../src/pages/treatment-options/medications/leflunomide.js" /* webpackChunkName: "component---src-pages-treatment-options-medications-leflunomide-js" */),
  "component---src-pages-treatment-options-medications-methotrexate-js": () => import("./../src/pages/treatment-options/medications/methotrexate.js" /* webpackChunkName: "component---src-pages-treatment-options-medications-methotrexate-js" */),
  "component---src-pages-treatment-options-medications-mycophenolate-js": () => import("./../src/pages/treatment-options/medications/mycophenolate.js" /* webpackChunkName: "component---src-pages-treatment-options-medications-mycophenolate-js" */),
  "component---src-pages-treatment-options-medications-nonsteroidal-anti-inflammatory-drugs-nsaids-js": () => import("./../src/pages/treatment-options/medications/nonsteroidal-anti-inflammatory-drugs-nsaids.js" /* webpackChunkName: "component---src-pages-treatment-options-medications-nonsteroidal-anti-inflammatory-drugs-nsaids-js" */),
  "component---src-pages-treatment-options-medications-other-pain-medications-js": () => import("./../src/pages/treatment-options/medications/other-pain-medications.js" /* webpackChunkName: "component---src-pages-treatment-options-medications-other-pain-medications-js" */),
  "component---src-pages-treatment-options-medications-rituximab-js": () => import("./../src/pages/treatment-options/medications/rituximab.js" /* webpackChunkName: "component---src-pages-treatment-options-medications-rituximab-js" */),
  "component---src-pages-useful-contacts-general-practitioners-js": () => import("./../src/pages/useful-contacts/general-practitioners.js" /* webpackChunkName: "component---src-pages-useful-contacts-general-practitioners-js" */),
  "component---src-pages-useful-contacts-index-js": () => import("./../src/pages/useful-contacts/index.js" /* webpackChunkName: "component---src-pages-useful-contacts-index-js" */),
  "component---src-pages-useful-contacts-lupus-wa-community-js": () => import("./../src/pages/useful-contacts/lupus-wa-community.js" /* webpackChunkName: "component---src-pages-useful-contacts-lupus-wa-community-js" */),
  "component---src-pages-useful-contacts-specialists-js": () => import("./../src/pages/useful-contacts/specialists.js" /* webpackChunkName: "component---src-pages-useful-contacts-specialists-js" */),
  "component---src-pages-useful-contacts-wa-hospital-immunology-and-rheumatology-clinics-js": () => import("./../src/pages/useful-contacts/wa-hospital-immunology-and-rheumatology-clinics.js" /* webpackChunkName: "component---src-pages-useful-contacts-wa-hospital-immunology-and-rheumatology-clinics-js" */)
}

