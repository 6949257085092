module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"lupus-info","short_name":"Lupus Info","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/lupus-wa-logo.png","include_favicon":true,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"36197b8328cf45b1ddd3be442e440d05"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
